import React, { useEffect } from 'react'
import { LazyComponent } from '../../components/LazyComponent'
import TagManager from 'react-gtm-module'

const LandingPage = () => {
  useEffect(() => {
    const gtmId = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE

    if (gtmId) {
      const tagManagerArgs = { gtmId }
      TagManager.initialize(tagManagerArgs)
    }
  }, [])

  return (
    <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
      <LazyComponent componentGetter={() => import('./_components/landingSection')}/>
      <LazyComponent componentGetter={() => import('./_components/aboutSection')}/>
      <LazyComponent componentGetter={() => import('./_components/featuresSection')}/>
      <LazyComponent componentGetter={() => import('../../components/footer/footer')}/>
    </div>
  )
}

export default LandingPage
